import translates from '../../lang'
import $ from "jquery";



$(document).ready(function() {
  function chooseCity(city_id){
    [...document.querySelectorAll("#cities_main div.element")].forEach((element) => {
      const a = element.querySelector('a');
      const img = element.querySelector('.img');

      if(a.getAttribute("data-uuid") == city_id){
        a.className = "selected";
        img.className = "img selected";
      }
      else {
        a.className = "";
        img.className = "img";
      }
    })
  }

  try {
    let currentCity = JSON.parse(localStorage.getItem('currentCity'));
    if (!currentCity) {
      console.log(currentCity);
      $('#selectCityModal').fadeIn();
    }
  } catch {
    localStorage.removeItem('currentCity');
    $('#selectCityModal').fadeIn();
  }
  
  const block = document.querySelector("#cities_main");
  const selectCityButton = document.querySelector('#select-city-button');


  
  api.getCities().then(({ cities }) => {
    block.innerHTML = `
      ${cities.map((city) => `
        <div class="element">
          <a data-uuid="${city.uuid}" class="">
            ${
              city.name
            }
          </a>
          <div class="img"></div>
        </div>
      `).join("")}
    `;

    document.querySelector('#cities_main div.element a').className = "selected";
    document.querySelector('#cities_main div.element div.img').className = "img selected";

    [...document.querySelectorAll("#cities_main div.element")].forEach((element) => {
      element.addEventListener("click", (e) => {
        const a = element.querySelector('a');
        chooseCity(a.getAttribute("data-uuid"));
      })
    })

    selectCityButton.addEventListener('click', (e) => {
      [...document.querySelectorAll("#cities_main a.selected")].forEach((a) => {
        e.preventDefault();
        const id = a.getAttribute("data-uuid");
        const city = cities.filter((city) => city.uuid === id)[0];
        localStorage.setItem("currentCity", JSON.stringify(city));
        location.reload()
      })
      
      // const id = a.getAttribute("data-uuid");
      // const city = cities.filter((city) => city.uuid === id)[0];
      // localStorage.setItem("currentCity", JSON.stringify(city));
      // location.reload()
    })

    let langElements = document.querySelectorAll('[data-translate]')
    for (let elem of langElements) {
      let lang = elem.getAttribute('data-translate')
      elem.innerHTML = translate(lang)
      elem.placeholder = translate(lang)
    }

  })

})



export const translate = (key) => {
  let lang = localStorage.getItem('currentLang') || 'ru'
  if (translates[key]) {
    return translates[key][lang.toLowerCase()]
  }
  return 'unknown';
}