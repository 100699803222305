const translates = {
  "news": {
    "ru": "Новости",
    "en": "News",
    "kz": "Жаңалықтар",
  },
  "delivery": {
    "ru": "Доставка",
    "en": "Delivery",
    "kz": "Жеткізу",
  },
  "reserv": {
    "ru": "Бронь столиков",
    "en": "Reservation of tables",
    "kz": "Үстелдерді брондау",
  },
  "catering": {
    "ru": "Кейтеринг",
    "en": "Catering",
    "kz": "Тамақтану",
  },
  "promotions": {
    "ru": "Акции",
    "en": "Special offers",
    "kz": "Акциялар",
  },
  "terms-of-use": {
    "ru": "Пользовательское соглашение",
    "en": "Terms of use",
    "kz": "Қолдану ережелері",
  },
  "Turandot-restaurant-chain": {
    "ru": "Сеть ресторанов Turandot",
    "en": "Turandot restaurant chain",
    "kz": "Turandot мейрамханалар желісі"
  },
  "Turandot-original-restaurant-chain": {
    "ru": "Сеть оригинальных ресторанов Turandot",
    "en": "Turandot original restaurant chain",
    "kz": "Turandot түпнұсқа мейрамханалар желісі"
  },
  "About-Turandot-Restaurant": {
    "ru": "О ресторане Turandot",
    "en": "About Turandot Restaurant",
    "kz": "Турандот мейрамханасы туралы"
  },
  "Theres-nothing-here": {
    "ru": "Здесь ничего нет",
    "en": "There's nothing here",
    "kz": "Мұнда ештеңе жоқ"
  },
  "Something-went-wrong": {
    "ru": "Кажется что-то пошло не так!",
    "en": "Something went wrong!",
    "kz": "Бірдеңе дұрыс болмады"
  },
  "About-Turandot-Chinese-Restaurant": {
    "ru": "О ресторане китайской кухни Turandot",
    "en": "About Turandot Chinese Restaurant",
    "kz": "Турандот қытай мейрамханасы туралы"
  },
  "Unforgettable-catering-for-your-events": {
    "ru": "Незабываемый кейтеринг для ваших мероприятий",
    "en": "Unforgettable catering for your events",
    "kz": "Сіздің іс-шараларыңыз үшін ұмытылмас тамақтану"
  },
  "Order-catering-for-your-events": {
    "ru": "Закажите кейтеринг для ваших мероприятий",
    "en": "Order catering for your events",
    "kz": "Іс-шараларыңызға тамақтандыруға тапсырыс беріңіз"
  },
  "Promotions-and-bonuses": {
    "ru": "Акции и бонусы",
    "en": "Promotions and bonuses",
    "kz": "Акциялар мен бонустар"
  },
  "Promotions-and-bonus-programs": {
    "ru": "Акции и бонусные программы",
    "en": "Promotions and bonus programs",
    "kz": "Акциялар мен бонустық бағдарламалар"
  },
  "Order-a-restaurant-in-Almaty": {
    "ru": "Заказать ресторан в Алматы",
    "en": "Order a restaurant in Almaty",
    "kz": "Алматы Алматыдағы мейрамханаға тапсырыс беру"
  },
  "Order-a-restaurant-in-Astana": {
    "ru": "Заказать ресторан в Астане",
    "en": "Order a restaurant in Astana",
    "kz": "Астанада мейрамханаға тапсырыс беріңіз"
  },
  "Order-a-restaurant-in-Shymkent": {
    "ru": "Заказать ресторан в Шымкенте",
    "en": "Order a restaurant in Shymkent",
    "kz": "Шымкенттегі мейрамханаға тапсырыс беріңіз"
  },
  "Order-a-restaurant-in-Karaganda": {
    "ru": "Заказать ресторан в Караганде",
    "en": "Order a restaurant in Karaganda",
    "kz": "​Қарағандыда мейрамханаға тапсырыс беру"
  },
  "Index__description": {
    "ru": "Сеть ресторанов с бесплатной доставкой в городах Алматы, Астана, Шымкемте и Караганде. Работаем с 11:00 до 23:00",
    "en": "A chain of restaurants with free delivery in the cities of Almaty, Astana, Shymkemt and Karaganda. We work from 11:00 to 23:00",
    "kz": "Алматы, Астана, Шымкент және Қарағанды ​​қалаларында тегін жеткізу бар мейрамханалар желісі. 11:00-ден 23:00-ге дейін жұмыс істейміз"
  },
  "About__description": {
    "ru": "Сеть Turandot, состоящая из 9 ресторанов, основанна в 1998 году и уже 25 лет на рынке. У нас настоящие китайские повара",
    "en": "The Turandot chain, consisting of 9 restaurants, was founded in 1998 and has been on the market for 25 years. We have real Chinese chefs",
    "kz": "9 мейрамханадан тұратын «Турандот» желісі 1998 жылы құрылған және нарықта 25 жылдан бері жұмыс істейді. Бізде нағыз қытай аспаздары бар"
  },
  "Catering__description": {
    "ru": "Мы формируем лучшие традиции кейтеринга с нотками китайской эстетики. Оставляйте заявку и мы обсудим с Вами каждую деталь",
    "en": "We form the best traditions of catering with a touch of Chinese aesthetics. Leave a request and we will discuss every detail with you",
    "kz": "Біз қытайлық эстетиканың әсерінен тамақтанудың ең жақсы дәстүрлерін қалыптастырамыз. Өтінім қалдырыңыз, біз сізбен барлық егжей-тегжейлі талқылаймыз"
  },
  "Promotions__description": {
    "ru": "Лучшие акции и бонусные программы для пользователей от сети ресторанов Turandot в городах Алматы, Астана, Шымкенте и Караганде",
    "en": "The best promotions and bonus programs for users from the Turandot restaurant chain in the cities of Almaty, Astana, Shymkent and Karaganda",
    "kz": "Алматы, Астана, Шымкент және Қарағанды ​​қалаларындағы Turandot мейрамханалар желісінің пайдаланушылары үшін ең жақсы акциялар мен бонустық бағдарламалар"
  },
  "Booking__description": {
    "ru": "Бронирование столика в сети оригинальных ресторанов в китайском стиле",
    "en": "Booking a table in a network of original Chinese-style restaurants",
    "kz": "Қытай стиліндегі түпнұсқа мейрамханалар желісінде үстелді брондау",
  },
  "Booking__Almaty__description": {
    "ru": "Бронирование столика в сети оригинальных ресторанов в китайском стиле - Turandot в городе Алматы",
    "en": "Booking a table in a network of original Chinese-style restaurants - Turandot in Almaty",
    "kz": "Қытай стиліндегі түпнұсқа мейрамханалар желісінде үстелді брондау - Алматыдағы Турандот",
  },
  "Booking__Astana__description": {
    "ru": "Бронирование столика в сети оригинальных ресторанов в китайском стиле - Turandot  в городе Астана",
    "en": "Booking a table in a network of original Chinese-style restaurants - Turandot in Astana",
    "kz": "Астанадағы Турандот - қытай стиліндегі түпнұсқа мейрамханалар желісінде үстелді брондау",
  },
  "Booking__Shymkent__description": {
    "ru": "Бронирование столика в оригинальном ресторане в китайском стиле - Turandot в городе Шымкент ",
    "en": "Booking a table in the original Chinese style restaurant - Turandot in Shymkent",
    "kz": "Шымкенттегі «Турандот» қытай стиліндегі түпнұсқа мейрамханада үстелге тапсырыс беру",
  },
  "Booking__Qarangandy__description": {
    "ru": "Бронирование столика в оригинальном ресторане в китайском стиле - Turandot в городе Караганда",
    "en": "Booking a table in an original Chinese style restaurant - Turandot in Karaganda city",
    "kz": "Қытай стиліндегі түпнұсқа мейрамханада үстелді брондау - Қарағанды ​​қаласындағы Турандот",
  },
  "restaurants": {
    "ru": "Рестораны",
    "en": "Restaurants",
    "kz": "Мейрамханалар",
  },
  "restaurant": {
    "ru": "Ресторан",
    "en": "Restaurant",
    "kz": "Мейрамхана",
  },
  "almaty": {
    "ru": "Алматы",
    "en": "Almaty",
    "kz": "Алматы",
  },
  "login": {
    "ru": "Войти",
    "en": "Login",
    "kz": "Кіру",
  },

  "since_20": {
    "ru": "Готовим из лучших продуктов более 25 лет",
    "kz": "25 жылдан астам ең жақсы өнімдерден дайындаймыз",
    "en": "Cooking with the best ingredients for over 25 years"
  },

  "few_30_days": {
    "ru": "Менее чем за 30 дней",
    "kz": "30 күннен аз уақыт ішінде",
    "en": "In less than 30 minutes"
  },

  "fav_places": {
    "ru": "Ваши любимые места",
    "kz": "Сіздердің сүйікті орындарыңыз",
    "en": "Your favorite places"
  },
  "menu": {
    "ru": "Меню",
    "kz": "Мәзір",
    "en": "Menu"
  },
  "order_sent": {
    "ru": "Заказ отправлен",
    "kz": "Тапсырыс жіберілді",
    "en": "Order is sent"
  },
  "rest_founded": {
    "ru": "Ресторан основан в 1998 году",
    "kz": "Мейрамхана 1998 жылы құрылған",
    "en": "The restaurant was founded in 1998"
  },
  "why_turandot": {
    "ru": "Турандот – дочь китайского императора, самоуверенная красавица, одно время не желавшая связывать себя узами брака, но в итоге познавшая счастье любить и быть любимой.",
    "kz": "Турандот – өзіне-өзі сенімді сұлу,  бір кездері некелесуді қаламаған, бірақ соңында сүю және өзін сүйдіру бақытына ие болған Қытай императорының қызы. ",
    "en": "Turandot is the daughter of the Chinese emperor, a self-confident beauty who at one time did not wish to tie the knot, but in the end experienced happiness of loving and being loved."
  },

  "about_play": {
    "ru": `Кратко о пьесе`,
    "kz": `Қысқаша пьеса туралы`,
    "en": `Briefly about the play`
  },

  "run_from": {
    "ru": `Бегущий из собственных земель, завоеванных неприятелем, принц Калаф влюбляется в китайскую принцессу, лишь увидев её портрет.`,
    "kz": `Жауы бағындырған өзінің туған  жерінен қашқан Калаф ханзада қытай ханшайымының суретін көре салысымен оған ғашық болып қалады. `,
    "en": `Running  from his own lands conquered by the enemy, Prince Calaf falls in love with a Chinese princess, only having seen her portrait.`
  },

  "more_23_years": {
    "ru": "Уже 25 лет мы дарим незабываемые впечатления нашим гостям в четырёх крупных городах Казахстана.",
    "kz": "Міне 25 жыл бойы біз Қазақстанның төрт ірі қаласында қонақтарымызға ұмытылмас әсерлер сыйлап келеміз.",
    "en": "For about 25 years we have been presenting unforgettable impressions to our guests in four major cities of Kazakhstan."
  },

  "our_dishes": {
    "ru": "Наши блюда придутся по душе ценителям изысканной кухни",
    "kz": "Біздің тағамдарымыз талғампаз асты бағалай білетін жандардың көңілінен шығады. ",
    "en": "Our dishes will please connoisseurs of gourmet cuisine"
  },

  "more": {
    "ru": "подробнее",
    "kz": "көбірек",
    "en": "more"
  },

  "confidentiality": {
    "ru": "Политика конфиденциальность",
    "en": "Confidentiality policy",
    "kz": "Құпиялылық саясаты",
  },

  "23_on_market": {
    "ru": "25+ лет на рынке",
    "kz": "Нарықта 25+ жыл",
    "en": "25+ years on the market"
  },

  "quater_of_century": {
    "ru": "Почти четверть века мы посвящаем вас в тонкости китайской культуры и кухни",
    "kz": "Ширек ғасырға жуық уақыт бойы біз сіздерді Қытай мәдениеті мен асханасының қыр-сырымен таныстырып келеміз",
    "en": "For almost a quarter of a century we devote you to delicacy of Chinese culture and cuisine"
  },
  "original_taste": {
    "ru": `Оригинальный вкус`,
    "kz": `Өзіндік дәм`,
    "en": `Original taste`
  },

  "original_taste_desc": {
    "ru": `Настоящие китайские повара, особенные рецепты, высококлассное обслуживание`,
    "kz": `Нағыз қытай аспаздары, ерекше рецепттер, жоғары сапалы қызмет көрсету`,
    "en": `Real Chinese chefs, special recipes, high-quality service`
  },

  "9_rests": {
    "ru": `9 ресторанов`,
    "kz": `9  мейрамхана`,
    "en": `9 restaurants`
  },
  "9_rests_desc": {
    "ru": `Мы с радостью примем вас в одном из наших ресторанов или же порадуем вас своей доставкой`,
    "kz": `Біз сіздерді мейрамханаларымыздың бірінде  қонақ етіп  қуана қабылдаймыз немесе сізді жеткізіп берумен  қуанта аламыз`,
    "en": `We will be happy to host you in one of our restaurants or delight you with our delivery`
  },

  "cozy_interior": {
    "ru": "Уютный интерьер",
    "kz": "Жайлы интерьер",
    "en": "Cozy interior"
  },

  "delivery?": {
    "ru": "Оформим доставку?",
    "kz": "Жеткізуді ресімдейміз бе?",
    "en": "Will we arrange delivery?"
  },

  "own_delivery_service": {
    "ru": "Своя служба доставки",
    "kk": "Жеке жеткізу қызметі",
    "en": "Own delivery service",
  },

  "30_minutes": {
    "ru": "Мы приготовим и доставим ваш заказ менее, чем за 30 дней",
    "kz": "Тапсырысты 30 минуттан аз уақыт ішінде дайындап, жеткіземіз",
    "en": "We will prepare and deliver your order in less than 30 minutes"
  },

  "order_delivery": {
    "ru": "Заказать доставку",
    "kz": "Жеткізуге тапсырыс беру",
    "en": "Order delivery"
  },
  "delivery_zones": {
    "ru": "Зоны доставки",
    "kz": "Жеткізу аймақтары",
    "en": "Delivery zones"
  },

  "lets_choose": {
    "ru": "Давайте выберем самые удобные места для вас и ваших друзей",
    "kz": "Сізге және сіздің достарыңыз үшін ең қолайлы орындарды таңдайық",
    "en": "Let's choose the most convenient places for you and your friends"
  },
  "call_us": {
    "ru": "Позвоните, и мы поможем определиться с меню и размещением",
    "kz": "Бізге қоңырау шалыңыз, біз сізге мәзір мен отыратын жерді  таңдауға көмектесеміз ",
    "en": "Call us and we will help you decide on the menu and placement"
  },
  "book_table": {
    "ru": "Забронировать столик",
    "kz": "Үстелді брондау ",
    "en": "Book a table"
  },
  "holiday_with_dished": {
    "ru": "Хотите провести праздник с любимыми блюдами?",
    "kz": "Мерекені сүйікті тағамдарыңызбен бірге өткізгілерңіз келе ме? ",
    "en": "Would you like to spend a holiday with your favorite dishes?"
  },
  "we_organize": {
    "ru": "Организуем выездной ресторан в лучших традициях китайской кухни, создав незабываемые впечатления для ваших гостей",
    "kz": "Біз қытай асханасының ең жақсы дәстүрлерінде қонақтарыңызға ұмытылмас әсер қалдыра  отырып, көшпелі мейрамхана ұйымдастырамыз.",
    "en": "We organize on-site restaurant in the best traditions of Chinese cuisine, creating unforgettable experience for your guests"
  },
  "order_catering": {
    "ru": "Заказать кейтеринг",
    "kz": "Кейтеринге тапсырыс беру",
    "en": "Order catering"
  },
  "reward": {
    "ru": "Награда",
    "kz": "Сыйақы",
    "en": "Reward"
  },
  "1000_bonuses": {
    "ru": "Скачайте мобильное приложение и получите 1000 бонусов после первой оплаты",
    "kz": "Мобильді қосымшаны жүктеп алып, алғашқы тапсырыстан кейін 1000 бонус алыңыз",
    "en": "Download the mobile application and get 1000 bonuses after first order"
  },
  "5_percent": {
    "ru": "И станьте участником бонусной программы возвращая их по 5% с каждой покупки",
    "kz": "Және оларды әр сатып алудан 5%-дан қайтара отырып, бонустық бағдарламаның қатысушысы болыңыз",
    "en": "And become a member of the Bonus Program by returning them on 5% from each purchase"
  },
  "available_in": {
    "ru": "Доступно в",
    "kz": "Қол жетімді",
    "en": "Available in the"
  },
  "app_store": {
    "ru": "Доступно в App Store",
    "kz": "App Store-да қол жетімді",
    "en": "Available in the App Store"
  },
  "play_market": {
    "ru": "Доступно в Play Market",
    "kz": "Play Market-те қол жетімді",
    "en": "Available in Play Market"
  },
  "hello_sir": {
    "ru": "Привет, Рихард!",
    "kz": "Сәлем, Рихард!",
    "en": "Hi Richard!"
  },
  "ur_bonuses": {
    "ru": "Ваши бонусы",
    "kz": "Сіздің бонустарыңыз",
    "en": "Your bonuses"
  },
  "spend": {
    "ru": "Потратить",
    "kz": "Жұмсау",
    "en": "Spend"
  },
  "my_cashback": {
    "ru": "Мой кэшбек",
    "kz": "Менің кешбэгім",
    "en": "My cashback"
  },
  "how_increase": {
    "ru": "Как увеличить?",
    "kz": "Қалай арттыруға болады?",
    "en": "How to increase?"
  },
  "my_orders": {
    "ru": "Мои заказы",
    "kz": "Менің тапсырыстарым",
    "en": "My orders"
  },
  "my_cards": {
    "ru": "Мои карты",
    "kz": "Менің карталарым",
    "en": "My cards"
  },

  "favourites": {
    "ru": "Избранное",
    "kz": "Таңдаулылар",
    "en": "Favorites"
  },

  "feedback": {
    "ru": "Оставить отзыв",
    "kz": "Пікір қалдыру",
    "en": "Leave feedback"
  },

  "share_review": {
    "ru": "Поделитесь своими впечатлениями о нашем сервисе",
    "kz": "Біздің қызмет туралы тәжірибеңізбен бөлісіңіз",
    "en": "Share your experience about our service"
  },

  "hot_phone": {
    "ru": "Телефон горячей линии",
    "kz": "Жедел желі телефоны",
    "en": "Hotline phone number"
  },

  "kitchen": {
    "ru": "Кухня",
    "kz": "Тағамдар",
    "en": "Kitchen"
  },
  "special": {
    "ru": "Фирменное",
    "kz": "Фирмалық",
    "en": "Сhef's special"
  },

  "new_items": {
    "ru": "Новинки",
    "kz": "Жаңа өнімдер",
    "en": "New items"
  },

  "popular": {
    "ru": "Популярное",
    "kz": "Танымал",
    "en": "Popular"
  },
  "chefs": {
    "ru": "Повара",
    "kz": "Аспаздар",
    "en": "Chefs"
  },

  "reservs": {
    "ru": "Бронирование",
    "kz": "Брондау",
    "en": "Reservations"
  },
  "about_rest": {
    "ru": "О ресторане",
    "kz": "Мейрамхана туралы",
    "en": "About the restaurant"
  },

  "contacts": {
    "ru": "Контакты",
    "kz": "Байланыстар",
    "en": "Contacts"
  },

  "suggestions": {
    "ru": "Жалобы/предложение",
    "kz": "Шағымдар/ұсыныстар",
    "en": "Complaints/suggestions"
  },

  "privacy_policy": {
    "ru": "Политика конфиденциальности",
    "kz": "Құпиялылық саясаты",
    "en": "Privacy policy"
  },

  "website": {
    "ru": "<span>Сайт разработан компанией</span> <a href=\"https://yoko.space\" target=\"_blanc\">yoko.space</a>",
    "kz": "<span>Сайтты <a href=\"https://yoko.space\" target=\"_blanc\">yoko.space</a> компаниясы жасаған.</span>",
    "en": "<span>Website is developed by</span> <a href=\"https://yoko.space\" target=\"_blanc\">yoko.space</a>"
  },
  "unforget_catering": {
    "ru": "Незабываемый кейтеринг для ваших мероприятий",
    "kz": "Сіздің іс-шараларыңыз үшін ұмытылмас кейтеринг",
    "en": "Unforgettable catering for your events"
  },
  "best_traditions": {
    "ru": "Мы формируем лучшие традиции кейтеринга с нотками китайской эстетики",
    "kz": "Біз қытай эстетикасының ноталарымен кейтерингтің ең жақсы дәстүрлерін қалыптастырамыз",
    "en": "We form the best traditions of catering with notes of Chinese aesthetics"
  },

  "ur_phone": {
    "ru": "Ваш телефон",
    "kz": "Телефоныңыз",
    "en": "Your phone"
  },

  "discuss": {
    "ru": "Обсудить",
    "kz": "Талқылау",
    "en": "Discuss"
  },

  "create_menu": {
    "ru": "Составить меню",
    "kz": "Мәзір жасау",
    "en": "Create a menu"
  },
  "leave_phone": {
    "ru": "Оставьте свой номер телефона и мы созвонимся, чтобы всё обсудить",
    "kz": "Телефон нөміріңізді қалдырыңыз, барлығын талқылау үшін біз сізге қоңырау шаламыз",
    "en": "Leave your phone number and we will call you to discuss everything"
  },
  "best_welcome": {
    "ru": "Мы берём на себя все заботы, чтобы обеспечить гостям поистине лучший приём",
    "kz": "Біз қонақтарға ең жақсы  қабылдауды қамтамасыз ету үшін, барлық қамқорлықты өз мойнымызға аламыз",
    "en": "We take care of everything to provide guests with a truly best welcome."
  },
  "organization": {
    "ru": "Организация",
    "kz": "Ұйымдастыру",
    "en": "Organization"
  },
  "team": {
    "ru": "Опытная команда",
    "kz": "Тәжірибелі команда",
    "en": "Experienced team"
  },

  "skills": {
    "ru": "Отточенные навыки, харизма и удовольствие от своей работы",
    "kz": "Кемеліне келген дағдылар, тартымдылық және өз жұмысынан ләззат алу",
    "en": "Perfected skills, charisma and pleasure from their work"
  },

  "rich_choice": {
    "ru": "Богатый выбор",
    "kz": "Мол таңдау",
    "en": "Rich choice"
  },
  "help_menu": {
    "ru": "Мы поможем разработать меню, которое не позволит равнодушным",
    "kz": "Біз бей-жай қалдырмайтын мәзір әзірлеуге көмектесеміз",
    "en": "We will help to develop a menu that will not allow to stay indifferent"
  },

  "catering_types": {
    "ru": "Виды кейтеринга",
    "kz": "Кейтеринг түрлері",
    "en": "Types of catering"
  },
  "we_offer": {
    "ru": "Мы предлагаем особенные мероприятия с уникальной стилизацией, грамотно организованной локацией и вековыми традициями китайской кухни, способной привести в восторг даже заядлого гурмана",
    "kz": "Біз ерекше стилі, жақсы ұйымдастырылған орналасуы және қытай асханасының ғасырлар бойы қалыптасқан дәстүрлері бар ерекше іс-шараларды ұсынамыз, олар ең кірпияз деген тамақ талғағыш жанды да шаттандыра алады",
    "en": "We offer special events with a unique style, well-organized location and centuries-old traditions of Chinese cuisine that can delight even a big gourmet"
  },

  "banket": {
    "ru": "Банкет",
    "kz": "Банкет",
    "en": "Banquet"
  },

  "pop_format": {
    "ru": "Популярный формат торжественных мероприятий",
    "kz": "Мерекелік іс-шаралардың салтанатты форматы",
    "en": "Popular format of festive events"
  },
  "relevent_meetings": {
    "ru": "Актуален для встреч по любому поводу",
    "kz": "Кез келген себеппен кездесулер үшін тиімді",
    "en": "Relevant for meetings on any occasion"
  },
  "view_menu": {
    "ru": "Смотреть меню",
    "kz": "Мәзірді қарау",
    "en": "View menu"
  },
  "order": {
    "ru": "Заказать",
    "kz": "Тапсырыс беру",
    "en": "Order"
  },
  "reception": {
    "ru": "Фуршет",
    "kz": "Фуршет",
    "en": "Standing reception"
  },
  "free_move": {
    "ru": "Свободное перемещение гостей и выбор блюд. Идеальный вариант для светских вечеров и деловых встреч",
    "kz": "Қонақтардың еркін қозғалысы және тағамдарды таңдау. Әлеуметтік іс-шаралар мен іскерлік кездесулер үшін өте қолайлы",
    "en": "Free movement of guests and choice of dishes. Ideal for social evenings and business meetings"
  },
  "ideal_for_meetings": {
    "ru": "Идеальный вариант для светских вечеров и деловых встреч",
    "kz": "Зайырлы кештер мен іскерлік кездесулер үшін таптырмас нұсқа",
    "en": "Ideal for social evenings and business meetings"
  },
  "on_site_cuisine": {
    "ru": "Выездная кухня",
    "kz": "Көшпелі ас-үй",
    "en": "On-site cuisine"
  },
  "unique_recipes": {
    "ru": "Уникальные рецепты, особая подача и яркая атмосфера, создающаяся исключительно для вас",
    "kz": "Бірегей рецепттер, ерекше ұсыным және тек сіздерге арналған жарқын атмосфера",
    "en": "Unique recipes, special presentation and strong ambience created exclusively for you"
  },
  "how_it_go": {
    "ru": "Как это проходит",
    "kz": "Бұл қалай өтеді",
    "en": "How does it go"
  },
  "discuss_event": {
    "ru": "Обсудим Ваше мероприятие",
    "kz": "Біз сіздің оқиғаңызды талқылаймыз",
    "en": "We will discuss your event"
  },
  "ur_name": {
    "ru": "Ваше имя",
    "kz": "Сіздің есіміңіз",
    "en": "Your name"
  },
  "phone": {
    "ru": "Телефон",
    "kz": "Телефон",
    "en": "Phone"
  },
  "call_back": {
    "ru": "Перезвоните мне",
    "kz": "Маған қайта қоңырау шалыңыз",
    "en": "Call me back"
  },
  "personal_data": {
    "ru": "Личные данные",
    "kz": "Жеке мәліметтер",
    "en": "Personal data"
  },
  "order_history": {
    "ru": "История заказов",
    "kz": "Тапсырыстар тарихы",
    "en": "Order history"
  },
  "my_addresses": {
    "ru": "Мои адреса",
    "kz": "Менің мекенжайларым",
    "en": "My addresses"
  },
  "featured_products": {
    "ru": "Избранные товары",
    "kz": "Таңдаулы тауарлар",
    "en": "Featured products"
  },
  "sign_out": {
    "ru": "Выйти из аккаунта",
    "kz": "Аккаунттан шығу",
    "en": "Sign out"
  },
  "your_bonuses": {
    "ru": "Ваши бонусы",
    "kz": "Сіздің бонустарыңыз",
    "en": "Your bonuses"
  },
  "my_profile": {
    "ru": "Мой профиль",
    "kz": "Менің профиль",
    "en": "My profile"
  },
  "quit": {
    "ru": "Выйти",
    "kz": "Шығу",
    "en": "Sign out"
  },
  "bonus_letter": {
    "ru": "Б",
    "kz": "Б",
    "en": "B"
  },
  "my_discount": {
    "ru": "Моя скидка",
    "kz": "Менің жеңілдіктерім",
    "en": "My discount"
  },
  "next_level": {
    "ru": "До следующего уровня",
    "kz": "Келесі деңгейге дейін",
    "en": "To the next level"
  },
  "tenge_alias": {
    "ru": "тг",
    "kz": "тг",
    "en": "tg"
  },
  "invite_friend": {
    "ru": "Пригласи друга",
    "kz": "Досыңды шақыр",
    "en": "Invite a friend"
  },
  "invite_text": {
    "ru": "За каждого приглашенного друга вы получите скидку на 1000тг. на любую сумму заказа",
    "kz": "Шақырылған әрбір дос үшін сіз 1000 теңгеге жеңілдік аласыз, тапсырыстың кез келген сомасына",
    "en": "For each invited friend, you will receive a discount of 1000 tenge for any amount of the order"
  },
  "friends_promocode": {
    "ru": "Промокод для друзей",
    "kz": "Достарға арналған промокод",
    "en": "Promo code for friends"
  },
  "profile_name": {
    "ru": "Имя",
    "kz": "Аты",
    "en": "Name"
  },
  "profile_phone": {
    "ru": "Телефон",
    "kz": "Телефоны",
    "en": "Phone"
  },
  "profile_birthdate": {
    "ru": "Дата рождения",
    "kz": "Туған күні",
    "en": "Date of Birth"
  },
  "profile_gender": {
    "ru": "Пол",
    "kz": "Жынысы",
    "en": "Gender"
  },
  "profile_gender_male": {
    "ru": "Мужской",
    "kz": "Еркек",
    "en": "Male"
  },
  "profile_gender_female": {
    "ru": "Женский",
    "kz": "Әйел",
    "en": "Female"
  },
  "save": {
    "ru": "Сохранить",
    "kz": "Сақтау",
    "en": "Save"
  },
  "order_number": {
    "ru": "Заказ №",
    "kz": "Тапсырыс №",
    "en": "Order No."
  },
  "repeat": {
    "ru": "Повторить",
    "kz": "Қайталау",
    "en": "Repeat"
  },
  "more_detailed": {
    "ru": "Подробнее",
    "kz": "Толығырақ",
    "en": "More detailed"
  },
  "add_address": {
    "ru": "Добавить адрес",
    "kz": "Мекенжайды қосу",
    "en": "Add an address"
  },
  "map": {
    "ru": "Карта",
    "kz": "Карта",
    "en": "Map"
  },
  "entrance": {
    "ru": "Подъезд",
    "kz": "Кіреберіс",
    "en": "Entrance"
  },
  "floor": {
    "ru": "Этаж",
    "kz": "Қабат",
    "en": "Floor"
  },
  "flat": {
    "ru": "Квартира",
    "kz": "Пәтер",
    "en": "Flat"
  },
  "block": {
    "ru": "Блок",
    "kz": "Блок",
    "en": "Block"
  },
  "address": {
    "ru": "Адрес",
    "kz": "Мекенжай",
    "en": "Address"
  },
  "add": {
    "ru": "Добавить",
    "kz": "Қосу",
    "en": "Add"
  },
  "address_commentary": {
    "ru": "Комментарий к адресу",
    "kz": "Мекенжайға пікір",
    "en": "Comment for address"
  },
  "choose_city": {
    "ru": "Выберите город",
    "kz": "Қаланы таңдаңыз",
    "en": "Choose city"
  },
  "nursultan": {
    "ru": "Нур-Султан",
    "kz": "Нұр-Сұлтан",
    "en": "Nur-Sultan"
  },
  "karagandy": {
    "ru": "Караганда",
    "kz": "Қарағанды",
    "en": "Qaragandy"
  },
  "shymkent": {
    "ru": "Шымкент",
    "kz": "Шымкент",
    "en": "Shymkent"
  },
  "deliver_not_yourself": {
    "ru": "Заказать не себе",
    "kz": "Өзіңізге тапсырыс емес",
    "en": "Order not for yourself"
  },
  "deliver_to_another": {
    "ru": "Доставить другому человеку",
    "kz": "Басқа адамға жеткізу",
    "en": "Deliver to another person"
  },
  "deliver_to_home": {
    "ru": "Доставьте мне домой",
    "kz": "Менің үйіме жеткізіңіз",
    "en": "Deliver to my home"
  },
  "take_myself": {
    "ru": "Самовывоз",
    "kz": "Мен өзім аламын",
    "en": "I'll take myself"
  },
  "cart_is_empty": {
    "ru": "Корзина пуста",
    "kz": "Себет бос",
    "en": "Cart is empty"
  },
  "signin_or_register": {
    "ru": "Войдите или создайте аккаунт, чтобы продолжить заказ",
    "kz": "Тапсырысты жалғастыру үшін кіріңіз немесе тіркеліңіз",
    "en": "Login or create an account to proceed with the order"
  },
  "create_account": {
    "ru": "Создать аккаунт",
    "kz": "Аккаунты жасау",
    "en": "Create an account"
  },
  "guestcount": {
    "ru": "Количество персон",
    "kz": "Адам саны",
    "en": "Number of persons"
  },

  "receiver_name": {
    "ru": "Имя получателя",
    "kz": "Қабылдаушы аты",
    "en": "Receiver name"
  },
  "enter_correct_number": {
    "ru": "Введите правильный номер",
    "kz": "Дұрыс нөмірді енгізіңіз",
    "en": "Enter valid phone number"
  },
  "enter_correct_name": {
    "ru": "Введите правильное имя",
    "kz": "Жарамды атау енгізіңіз",
    "en": "Enter valid name"
  },
  "delivery_address": {
    "ru": "Адрес доставки",
    "kz": "Жеткізу мекенжайы",
    "en": "Delivery address"
  },
  "choose_an_address": {
    "ru": "Выберите адрес",
    "kz": "Мекенжайды таңдаңыз",
    "en": "Select an address"
  },
  "deliver_when": {
    "ru": "Когда доставить?",
    "kz": "Қашан жеткізу?",
    "en": "When to deliver?"
  },
  "asap": {
    "ru": "Как можно скорее",
    "kz": "Мүмкіндігінше тезірек",
    "en": "As soon as possible"
  },
  "in_an_hour": {
    "ru": "Через час",
    "kz": "Бір сағаттан соң",
    "en": "In an hour"
  },
  "in_two_hours": {
    "ru": "Через два часа",
    "kz": "Екі сағаттан кейін",
    "en": "In two hours"
  },
  "by_card": {
    "ru": "Картой курьеру",
    "kz": "Курьерге карта бойынша",
    "en": "By card to the courier"
  },
  "terms-of-payment": {
    "ru": "Условия оплаты",
    "kz": "Төлем шарттары",
    "en": "Terms of payment",
  },
  "by_equiering": {
    "ru": "Картой онлайн",
    "kz": "Карта онлайн бойынша",
    "en": "By card online"
  },
  "by_cash": {
    "ru": "Наличными курьеру",
    "kz": "Қолма-қол ақшамен курьерге",
    "en": "By cash to the courier"
  },
  "order_commentary": {
    "ru": "Комментарий к заказу",
    "kz": "Тапсырысқа түсініктеме",
    "en": "Commentary for order"
  },
  "prepare_change_from": {
    "ru": "Подготовить сдачу с",
    "kz": "Қолма-қол ақша сомасы",
    "en": "Prepare change from"
  },
  "enter_correct_cash": {
    "ru": "Пожалуйста верно укажите с какой суммы подготовить сдачу",
    "kz": "Өзгерісті қанша дайындау керектігін дұрыс көрсетіңіз",
    "en": "Please enter correct cash"
  },
  "working_time": {
    "ru": "Время работы",
    "kz": "Жұмыс уақыты",
    "en": "Working time"
  },
  "restaurant_isnt_working": {
    "ru": "Ресторан не работает",
    "kz": "Мейрамхана жұмыс істемейді",
    "en": "Restaurant isn't working"
  },
  "restaurant_is_not_open_yet": {
    "ru": "Ресторан пока не открылся",
    "kz": "Мейрамхана әлі ашылмады",
    "en": "The restaurant is not open yet"
  },
  "delivery_is_not_open_yet": {
    "ru": "Доставка пока не открылась",
    "kz": "Жеткізу әлі ашылмады",
    "en": "Delivery is not open yet"
  },
  "confirm_order": {
    "ru": "Подтвердить заказ",
    "kz": "Тапсырысты растау",
    "en": "Confirm the order"
  },
  "spend_bonuses": {
    "ru": "Потратить бонусы",
    "kz": "Бонустарды жұмсау",
    "en": "Spend bonuses"
  },
  "able_to_spend": {
    "ru": "Можно потратить",
    "kz": "Жұмсай аласыз",
    "en": "Can be spent"
  },
  "how_much_bonuses_spend": {
    "ru": "Сколько бонусов потратить",
    "kz": "Қанша бонус жұмсау",
    "en": "How much bonus to spend"
  },
  "cannot_spend_more_bonuses": {
    "ru": "Нельзя потратить больше бонусов чем имеете",
    "kz": "Сізде бар бонустардан артық жұмсай алмайсыз",
    "en": "You cannot spend more bonuses than you have"
  },
  "cart": {
    "ru": "Корзина",
    "kz": "Себет",
    "en": "Cart"
  },
  "bonuses": {
    "ru": "Бонусы",
    "kz": "Бонустар",
    "en": "Bonuses"
  },
  "delivery_amount": {
    "ru": "Сумма доставки",
    "kz": "Жеткізу сомасы",
    "en": "Delivery amount"
  },
  "total": {
    "ru": "Итого",
    "kz": "Жалпы",
    "en": "Total"
  },
  "where_get_from": {
    "ru": "Откуда заберёте",
    "kz": "Қайдан аласыз",
    "en": "Where will you pick up"
  },
  "you_are_ordering": {
    "ru": "Вы заказываете",
    "kz": "Сіз тапсырыс бересіз",
    "en": "You order"
  },
  "one_dish": {
    "ru": "блюдо",
    "kz": "тағамға",
    "en": "dish"
  },
  "two_to_four_dishes": {
    "ru": "блюда",
    "kz": "тағамға",
    "en": "dishes"
  },
  "many_dishes": {
    "ru": "блюд",
    "kz": "тағамға",
    "en": "dishes"
  },
  "dont_forget_to_add": {
    "ru": "Не забудьте добавить",
    "kz": "Қосуды ұмытпаңыз",
    "en": "Don't forget to add"
  },
  "add_for": {
    "ru": "Добавить за",
    "kz": "Қосу бағасы",
    "en": "Add for"
  },
  "table_order": {
    "ru": "Бронь столиков",
    "kz": "Үстелді брондау",
    "en": "Table reservations"
  },
  "choose_order_details": {
    "ru": "Выберите дату и время бронирования и мы подберём самое удобное место для вас",
    "kz": "Брондау күні мен уақытын таңдаңыз және біз сіз үшін ең қолайлы орынды таңдаймыз",
    "en": "Choose the date and time of reservation and we will select the most convenient place for you"
  },
  "restaurant": {
    "ru": "Ресторан",
    "kz": "Мейрамхана",
    "en": "Restaurant"
  },
  "choose_restaurant": {
    "ru": "Выберите ресторан",
    "kz": "Мейрамхана таңдаңыз",
    "en": "Choose a restaurant"
  },
  "which_day": {
    "ru": "В какой день",
    "kz": "Қай күні",
    "en": "On what day"
  },
  "choose_day": {
    "ru": "Выберите день",
    "kz": "Күнді таңдаңыз",
    "en": "Сhoose a day"
  },
  "which_time": {
    "ru": "В какое время",
    "kz": "Қай уақытта",
    "en": "At what time"
  },
  "choose_time": {
    "ru": "Выберите время",
    "kz": "Уақытты таңдаңыз",
    "en": "Choose a time"
  },
  "guests_number": {
    "ru": "Количество гостей",
    "kz": "Қонақтар саны",
    "en": "Number of guests"
  },
  "ready": {
    "ru": "Готово",
    "kz": "Дайын",
    "en": "Done"
  },
  "sign_up": {
    "ru": "Зарегистрируйтесь",
    "en": "Register",
    "kz": "Тіркеліңіз"
  },
  "no_account": {
    "ru": "Нет аккаунта? <a href=\"#registration\" class=\"open\">Зарегистрируйтесь</a>",
    "en": "Have no account? <a href=\"#registration\" class=\"open\">Register</a>",
    "kz": "Аккаунт жоқ па? <a href=\"#registration\" class=\"open\">Тіркеліңіз</a>"
  },
  "forgot_password": {
    "ru": "Забыли пароль?",
    "kz": "Жасырын сөзді ұмыттыңыз ба?",
    "en": "Forgot password?"
  },
  "password_field_required": {
    "ru": "Поле \"пароль\" обязательно к заполнению.",
    "kz": "\"Жасырын сөзді\" енгізу керек",
    "en": "\"Password\" field is required to fill out"
  },
  "password": {
    "ru": "Пароль",
    "kz": "Жасырын сөз",
    "en": "Password"
  },
  "enter_phone_format": {
    "ru": "Пожалуйста введите телефон в формате: <br /> 8(7xx)xxx-xx-xx.",
    "en": "Please enter your phone number in the following format: <br /> 8(7xx)xxx-xx-xx.",
    "kz": "Телефон нөміріңізді келесі форматта енгізіңіз: <br /> 8(7xx)xxx-xx-xx."
  },
  "enter_phone_to_authorize": {
    "ru": "Введите номер телефона, для авторизации на сайте",
    "kz": "Сайтқа тіркелу үшін телефон нөміріңізді енгізіңіз",
    "en": "Enter your phone number to authorize"
  },
  "registration": {
    "ru": "Регистрация",
    "kz": "Тіркелу",
    "en": "Sign up"
  },
  "enter_data_register": {
    "ru": "Введите свои данные, для регистрации на сайте",
    "kz": "Сайтқа тіркелу үшін мәліметтеріңізді енгізіңіз",
    "en": "Enter your data to sign up"
  },
  "name_field_required": {
    "ru": "Поле \"Имя\" обязательно к заполнению.",
    "kz": "\"Аты\" енгізу керек",
    "en": "\"Name\" field is required to fill out"
  },
  "promocode_if_exists": {
    "ru": "Промокод при наличии",
    "kz": "Промокод бар болса",
    "en": "If there's promocode"
  },
  "have_account": {
    "ru": "Уже есть аккаунт? <a href=\"#signin\" class=\"open\">Войти</a>",
    "kz": "Аккаунтыңыз бар ма? <a href=\"#signin\" class=\"open\">Кіру</a>",
    "en": "Already have an account? <a href=\"#signin\" class=\"open\">Sign in</a>"
  },
  "to_sign_up": {
    "ru": "Зарегистрироваться",
    "kz": "Тіркелу",
    "en": "Sign up"
  },
  "send_code": {
    "ru": "Выслать код",
    "kz": "Код жіберу",
    "en": "Send code"
  },
  "enter_phone_to_restore": {
    "ru": "Введите номер телфона для восстановления пароля",
    "kz": "Жасырын сөзді қалпына келтіру үшін телефон нөмірді енгізіңіз",
    "en": "Enter phone number to restore password"
  },
  "password_restoration": {
    "ru": "Восстановление пароля",
    "kz": "Жасырын сөзді қалпына келтіру",
    "en": "Password restoration"
  },
  "choose_best_place": {
    "ru": "Выберем лучшее место",
    "kz": "Ең қолайлы орындарды таңдайық",
    "en": "We'll choose the best place"
  },
  "deliver_in_thirty": {
    "ru": "Доставим от 60 минут",
    "kz": "60 минуттан кейин жеткиземиз",
    "en": "We will deliver from 60 minutes"
  },
  "delivery_to_home": {
    "ru": "Доставка домой",
    "kz": "Үйге жеткізу",
    "en": "Deliver to home"
  },
  "delivery_minimal": {
    "ru": "Наши курьеры привезут ваш заказ в удобное для вас время. Минимальная сумма заказа 4000 тг",
    "kz": "Біздің курьерлер тапсырысыңызды сізге ыңғайлы уақытта жеткізеді. Ең аз тапсырыс сомасы 4000 теңге",
    "en": "Our couriers will deliver your order at a convenient time for you. Minimum order amount 4000 tenge"
  },
  "Cалаты и закуски": {
    "ru": "Cалаты и закуски",
    "kz": "Салаттар мен тағамдар",
    "en": "Salads and snacks"
  },
  "Гункан суши": {
    "ru": "Гункан суши",
    "kz": "Гункан суши",
    "en": "Gunkan sushi"
  },
  "Горячие блюда, рыба, морепродукты": {
    "ru": "Горячие блюда, рыба, морепродукты",
    "kz": "Ыстық тағамдар, балық, теңіз өнімдері",
    "en": "Hot dishes, fish, seafood"
  },
  "Горячие блюда, мясо": {
    "ru": "Горячие блюда, мясо",
    "kz": "Ыстық тағамдар, ет",
    "en": "Hot dishes, meat"
  },
  "Мясо": {
    "ru": "Мясо",
    "kz": "Ет",
    "en": "Meat"
  },
  "Мясо (доставка)": {
    "ru": "Мясо (доставка)",
    "kz": "Ет (жеткізу)",
    "en": "Meat (delivery)"
  },
  "Пельмени, манты (доставка)": {
    "ru": "Пельмени, манты (доставка)",
    "kz": "Тұшпара (жеткізу)",
    "en": "Dumplings (delivery)",
  },
  "Пельмени": {
    "ru": "Пельмени",
    "kz": "Тұшпара",
    "en": "Dumplings"
  },
  "Печеное": {
    "ru": "Печеное",
    "kz": "Пісірілген",
    "en": "Baked"
  },
  "Печеное (доставка)": {
    "ru": "Печеное (доставка)",
    "kz": "Пісірілген (жеткізу)",
    "en": "Baked (delivery)"
  },
  "Прочее": {
    "ru": "Прочее",
    "kz": "Басқа",
    "en": "Other"
  },
  "Птица": {
    "ru": "Птица",
    "kz": "Құс",
    "en": "Poultry"
  },
  "Птица (доставка)": {
    "ru": "Птица (доставка)",
    "kz": "Құс (жеткізу)",
    "en": "Poultry (delivery)"
  },
  "Рыба": {
    "ru": "Рыба",
    "kz": "Балық",
    "en": "Fish"
  },
  "Рыба (доставка)": {
    "ru": "Рыба (доставка)",
    "kz": "Балық (жеткізу)",
    "en": "Fish (delivery)"
  },
  "Рыба,морепродукты": {
    "ru": "Рыба, морепродукты",
    "kz": "Балық, теңіз өнімдері",
    "en": "Fish, seafood"
  },
  "Салаты и холодные закуски": {
    "ru": "Салаты и холодные закуски",
    "kz": "Салаттар мен салқын тағамдар",
    "en": "Salads and cold appetizers"
  },
  "Салаты и холодные закуски (доставка)": {
    "ru": "Салаты и холодные закуски (доставка)",
    "kz": "Салаттар мен салқын тағамдар (жеткізу)",
    "en": "Salads and cold appetizers (delivery)"
  },
  "Соусы": {
    "ru": "Соусы",
    "kz": "Тұздықтар",
    "en": "Sauces"
  },
  "Супы": {
    "ru": "Супы",
    "kz": "Сорпалар",
    "en": "Soups"
  },
  "Супы (доставка)": {
    "ru": "Супы (доставка)",
    "kz": "Сорпалар (жеткізу)",
    "en": "Soups (delivery)"
  },
  "Тесто и гарниры": {
    "ru": "Тесто и гарниры",
    "kz": "Қамыр және гарнирлер",
    "en": "Dough and side dishes"
  },
  "Удаленные": {
    "ru": "Удаленные",
    "kz": "Жойылған",
    "en": "Deleted"
  },
  "Фирменные блюда (доставка)": {
    "ru": "Фирменные блюда (доставка)",
    "kz": "Фирмалық тағамдар (жеткізу)",
    "en": "Specialty dishes (delivery)"
  },
  "Хого": {
    "ru": "Хого",
    "kz": "Хого",
    "en": "Hogo"
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "": {
    "ru": "",
    "kz": "",
    "en": ""
  },
  "Горячие блюда, курица": {
    "ru": "Горячие блюда, курица",
    "kz": "Ыстық тағамдар, тауық еті",
    "en": "Hot dishes, chicken"
  },
  "Горячие блюда": {
    "ru": "Горячие блюда",
    "kz": "Ыстық тағамдар",
    "en": "Hot dishes"
  },
  "Горячие салаты": {
    "ru": "Горячие салаты",
    "kz": "Ыстық салаттар",
    "en": "Hot salads"
  },
  "Горячие салаты (доставка)": {
    "ru": "Горячие салаты (доставка)",
    "kz": "Ыстық салаттар (жеткізу)",
    "en": "Hot salads (delivery)"
  },
  "Гарниры": {
    "ru": "Гарниры",
    "kz": "Гарнирлер",
    "en": "Side dishes"
  },
  "Гарниры (доставка)": {
    "ru": "Гарниры (доставка)",
    "kz": "Гарнирлер (жеткізу)",
    "en": "Side dishes (delivery)"
  },
  "Блюда из конины": {
    "ru": "Блюда из конины",
    "kz": "Жылқы етінен жасалған тағамдар",
    "en": "Horse meat dishes"
  },
  "Сеты": {
    "ru": "Сеты",
    "kz": "Сеттер",
    "en": "Sets"
  },
  "Сашими": {
    "ru": "Сашими",
    "kz": "Сашими",
    "en": "Sashimi"
  },
  "Роллы": {
    "ru": "Роллы",
    "kz": "Роллдар",
    "en": "Rolls"
  },
  "Пицца": {
    "ru": "Пицца",
    "kz": "Пицца",
    "en": "Pizza"
  },
  "Новинки меню": {
    "ru": "Новинки меню",
    "kz": "Жаңа мәзір",
    "en": "New menu items"
  },
  "Напитки": {
    "ru": "Напитки",
    "kz": "Сусындар",
    "en": "Drinks"
  },
  "Десерты": {
    "ru": "Десерты",
    "kz": "Десерттер",
    "en": "Desserts"
  },
  "Десерты (доставка)": {
    "ru": "Десерты (доставка)",
    "kz": "Десерттер (жеткізу)",
    "en": "Desserts (delivery)"
  },
  "Комплексные обеды": {
    "ru": "Комплексные обеды",
    "kz": "комплексті түскі",
    "en": "Set meals"
  },
  "Лапша/Ганфан": {
    "ru": "Лапша/Ганфан",
    "kz": "Кеспе/Ганфан",
    "en": "Noodles/Ganfan"
  },
  "Дополнительно": {
    "ru": "Дополнительно",
    "kz": "Қосымша",
    "en": "Additionally"
  },
  "Фирменные блюда": {
    "ru": "Фирменные блюда",
    "kz": "Фирмалық тағамдар",
    "en": "Specialty dishes"
  },
  "Суши": {
    "ru": "Суши",
    "kz": "Суши",
    "en": "Sushi"
  },
  "Супы": {
    "ru": "Супы",
    "kz": "Сорпалар",
    "en": "Soups"
  },
  "Спринг роллы из рисовой бумаги": {
    "ru": "Спринг роллы из рисовой бумаги",
    "kz": "Күріш қағазынан спринг орамдары",
    "en": "Rice Paper Spring rolls"
  },
  "dish_for": {
    "ru": "блюд за",
    "kz": "тағам үшін",
    "en": "dishes for"
  },
  "new_sales_every_day": {
    "ru": "Новые скидки каждый день",
    "kz": "Күн сайын жаңа жеңілдіктер",
    "en": "New discounts every day"
  },
  "our_sales": {
    "ru": "Наши акции",
    "kz": "Біздің акциялар",
    "en": "Our special offers"
  },
  "turandot_event": {
    "ru": "Праздник от TURANDOT",
    "kz": "Турандот мерекесі",
    "en": "Holiday by turandot"
  },
  "catering_order": {
    "ru": "Заказ кейтеринга",
    "kz": "Тамақтану тапсырысы",
    "en": "Ordering catering"
  },
  "share_your_experience": {
    "ru": "Поделитесь своими впечатлениями о нашем сервисе",
    "kz": "Біздің қызмет туралы өз әсерлеріңізбен бөлісіңіз",
    "en": "Share your impressions about our service"
  },
  "give_bonuses_after_pay": {
    "ru": "Начисляем бонусные баллы после каждой оплаты",
    "kz": "Әр төлемнен кейін бонустық ұпайларды есептейміз",
    "en": "We give bonus points after each payment"
  },
  "more_detailed": {
    "ru": "Подробнее",
    "kz": "Толығырақ",
    "en": "More detailed"
  },
  "День рождения": {
    "ru": "День рождения",
    "kz": "Туған күн",
    "en": "Birthday"
  },
  "Бонус за привлечение гостя": {
    "ru": "Бонус за привлечение гостя",
    "kz": "Қонақты қосқан үшін Бонус",
    "en": "Bonus for attracting a guest"
  },
  "Регистрация пользователей": {
    "ru": "Регистрация пользователей",
    "kz": "Пайдаланушыларды тіркеу",
    "en": "User registration"
  },
  "Первая покупка": {
    "ru": "Первая покупка",
    "kz": "Бірінші сатып алу",
    "en": "First purchase"
  },
  "Посещение ресторана Жарокова": {
    "ru": "Посещение ресторана Жарокова",
    "kz": "Жароков мейрамханасына бару",
    "en": "Visit to Zharokov's restaurant"
  },
  "Начисление бонусов именинникам": {
    "ru": "Начисление бонусов именинникам",
    "kz": "Туған күн иелеріне бонустар есептеу",
    "en": "Accrual of bonuses on birthday"
  },
  "Начисление бонусов за заполнение данных в анкете": {
    "ru": "Начисление бонусов за заполнение данных в анкете",
    "kz": "Сауалнамадағы деректерді толтыру үшін бонустарды есептеу",
    "en": "Accrual of bonuses for filling in the data in the questionnaire"
  },
  "Бонус за первое посещение": {
    "ru": "Бонус за первое посещение",
    "kz": "Алғашқы келген үшін бонусы",
    "en": "Bonus for the first visit"
  },
  "valid_until": {
    "ru": "Действительно до",
    "kz": "Дейін жарамды",
    "en": "Valid until"
  },
  "banquet_order": {
    "ru": "Заказ банкета",
    "kz": "Банкет тапсырысы",
    "en": "Banquet order"
  },
  "make_event_unforgettable": {
    "ru": "Мы сделаем всё, чтобы ваш праздник или событие стало незабываемым",
    "kz": "Біз сіздің мерекеңізді немесе оқиғаңызды ұмытылмас ету үшін бәрін жасаймыз",
    "en": "We will do everything to make your holiday or event unforgettable"
  },
  "tell_more": {
    "ru": "Расскажите подробнее",
    "kz": "Толығырақ айтып берсеңіз",
    "en": "Tell us more"
  },
  "submit_application": {
    "ru": "Отправить заявку",
    "kz": "Өтінімді жіберу",
    "en": "Send a request"
  },
  "thank_you": {
    "ru": "Спасибо",
    "kz": "Рахмет",
    "en": "Thank you"
  },
  "thanks_for_order": {
    "ru": "Спасибо за ваш заказ!",
    "kz": "Тапсырыңыз үшін рахмет!",
    "en": "Thank you for order!"
  },
  "go_back_to_main": {
    "ru": "Вернуться на главную",
    "kz": "Басты бетке оралу",
    "en": "Go back to the main"
  },
  "thanks_for_helping_better": {
    "ru": "Мы благодарны Вам за то, что помогаете нам становиться лучшe",
    "kz": "Бізді жақсартуға көмектескеніңіз үшін сізге алғыс айтамыз",
    "en": "We are grateful to you for helping us become better"
  },
  "will_call_you": {
    "ru": "Мы свяжемся с Вами в скором времени.",
    "kz": "Біз сізге жақын арада хабарласамыз.",
    "en": "We will contact you shortly."
  },
  "manager_will_call_you": {
    "ru": "В скором времени с вами свяжется менеджер.",
    "kz": "Менеджер сізге жақын арада хабарласады.",
    "en": "The manager will contact you shortly."
  },
  "order_is_received": {
    "ru": "Ваш заказ принят в обработку.",
    "kz": "Сіздің тапсырысыңыз қабылданды.",
    "en": "Your order has been processed."
  },
  "not_spicy": {
    "ru": "Не острое",
    "kz": "Ащы емес",
    "en": "Spicy"
  },
  "mid_spicy": {
    "ru": "Среднее",
    "kz": "Орташа",
    "en": "Medium"
  },
  "spicy": {
    "ru": "Острое",
    "kz": "Ащы",
    "en": "Spicy"
  },
  "add_to_cart": {
    "ru": "Добавить в корзину",
    "kz": "Себетке қосу",
    "en": "Add to cart"
  },
  "Открытие нового ресторана в городе Шымкент": {
    "ru": "Открытие нового ресторана в городе Шымкент",
    "kz": "Шымкент қаласында жаңа мейрамхананың ашылуы",
    "en": "Opening of a new restaurant in Shymkent"
  },
  "В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34": {
    "ru": "В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34",
    "kz": "Шымкентте 22 маусымда Елшибек батыр 80 мекен-жайында жаңа мейрамхана ашылады",
    "en": "A new restaurant opens in Shymkent on June 22 at the address Yelshibek batyr, 80"
  },
  "Теперь для посещения ресторана необходимо сканировать QR код проекта Ашык": {
    "ru": "Теперь для посещения ресторана необходимо сканировать QR код проекта Ашык",
    "kz": "Енді мейрамханаға бару үшін «Ашық» жобасының QR кодын сканерлеу керек",
    "en": "Now, to visit the restaurant, you need to scan the QR code of the Ashyk project"
  },
  "Turandot подключен к проекту Ашык": {
    "ru": "Turandot подключен к проекту Ашык",
    "kz": "Турандот Ашық жобасына қосылған",
    "en": "Turandot is connected to the Ashyq project"
  },
  "В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34": {
    "ru": "В Шымкенте 22 июня открывается новый ресторан по адресу Конаева б.34",
    "kz": "Шымкентте 22 маусымда Елшибек батыр 80 мекен-жайында жаңа мейрамхана ашылады",
    "en": "A new restaurant opens in Shymkent on June 22 at the address Yelshibek batyr, 80"
  },
  "Теперь для посещения ресторана необходимо сканировать QR код проекта АшыкТеперь для посещения ресторана необходимо сканировать QR код проекта АшыкТеперь для посещения ресторана необходимо сканировать QR код проекта Ашык": {
    "ru": "Теперь для посещения ресторана необходимо сканировать QR код проекта Ашык",
    "kz": "Енді мейрамханаға бару үшін «Ашық» жобасының QR кодын сканерлеу керек",
    "en": "Now, to visit the restaurant, you need to scan the QR code of the Ashyk project"
  },
  "we_instagram": {
    "ru": "Мы в инстаграм",
    "kz": "Instagram парақшамыз",
    "en": "Our instagram"
  },
  "on_map": {
    "ru": "На карте",
    "kz": "Картада",
    "en": "On map"
  },
  "make_a_call": {
    "ru": "Позвонить",
    "kz": "Қоңырау шалу",
    "en": "Call us"
  },
  "write": {
    "ru": "Написать",
    "kz": "Бізге жазу",
    "en": "Write to us"
  },
  "complaints_and_advices": {
    "ru": "Жалобы и предложения",
    "kz": "Шағымдар мен ұсыныстар",
    "en": "Complaints and suggestions"
  },
  "Turandot (пр. Ханов Жанибека и Керея 18)": {
    "ru": "Turandot (пр. Ханов Жанибека и Керея 18)",
    "kz": "Turandot (Жәнібек пен Керей даңғылы 18)",
    "en": "Turandot (Zhanibek and Kerey avenue 18)"
  },
  "Turandot Туркестан": {
    "ru": "Turandot Туркестан",
    "kz": "Turandot Туркестан",
    "en": "Turandot Turkestan"
  },
  "Turandot Сары Арка": {
    "ru": "Turandot Сары Арка",
    "kz": "Turandot Сары Арқа",
    "en": "Turandot Sary Arqa"
  },
  "Turandot Караганда": {
    "ru": "Turandot Караганда",
    "kz": "Turandot Қарағанды",
    "en": "Turandot Qaragandy"
  },
  "Turandot Доставка": {
    "ru": "Turandot Доставка",
    "kz": "Turandot Жеткізу",
    "en": "Turandot Delivery"
  },
  "Turandot Абая": {
    "ru": "Turandot Абая",
    "kz": "Turandot Абай",
    "en": "Turandot Abay"
  },
  "Turandot Назарбаева": {
    "ru": "Turandot Назарбаева",
    "kz": "Turandot Назарбаев",
    "en": "Turandot Nazarbayeva"
  },
  "Turandot Жарокова": {
    "ru": "Turandot Жарокова",
    "kz": "Turandot Жароков",
    "en": "Turandot Zharokova"
  },
  "Turandot Достык": {
    "ru": "Turandot Достык",
    "kz": "Turandot Достық",
    "en": "Turandot Dostyk"
  },
  "Turandot Шымкент": {
    "ru": "Turandot Шымкент",
    "kz": "Turandot Шымкент",
    "en": "Turandot Shymkent"
  },
  "Turandot Шымкент 2": {
    "ru": "Turandot Шымкент 2",
    "kz": "Turandot Шымкент 2",
    "en": "Turandot Shymkent 2"
  },
  "wanna_delete_address": {
    "ru": "Вы действительно хотите удалить этот адрес?",
    "kz": "Осы мекенжайды жойғыңыз келе ме?",
    "en": "Do you want to delete this address?"
  },
  "wanna_delete_wish": {
    "ru": "Вы действительно хотите удалить это блюдо?",
    "kz": "Бұл тағамды жойғыңыз келе ме?",
    "en": "Do you want to delete this dish from favourites?"
  },
  "enter_code": {
    "ru": "Введите код",
    "kz": "Кодты енгізіңіз",
    "en": "Enter code"
  },
  "code": {
    "ru": "Код",
    "kz": "Код",
    "en": "Code"
  },
  "please_enter_all_sms": {
    "ru": "Пожалуйста введите все символы из sms.",
    "kz": "SMS-тегі барлық таңбаларды енгізіңіз.",
    "en": "Please enter all symbols from sms."
  },
  "send_again_with_count": {
    "ru": "Отправить повторно<span id=\"regSmsCounter\">(60)</span>",
    "kz": "Қайта жіберу<span id=\"regSmsCounter\">(60)</span>",
    "en": "Send again<span id=\"regSmsCounter\">(60)</span>"
  },
  "send_again": {
    "ru": "Отправить повторно",
    "kz": "Қайта жіберу",
    "en": "Send again"
  },
  "we_send_code_on_number": {
    "ru": "Мы выслали код на номер",
    "kz": "Кодты жібердік келесі нөмірге",
    "en": "We have send code on phone number"
  },
  "finishing": {
    "ru": "Завершение",
    "kz": "Аяқтау",
    "en": "Complete"
  },
  "come_up_with_new_password": {
    "ru": "Придумайте новый пароль для входа",
    "kz": "Жаңа құпия сөз жасаңыз",
    "en": "Create a new password"
  },
  "catering_kitchen_order": {
    "ru": "Заказ выездной кухни",
    "kz": "Көшпелі ас-үйге тапсырыс",
    "en": "Catering kitchen order"
  },
  "buffet_order": {
    "ru": "Заказ фуршета",
    "kz": "Фуршетке тапсырыс",
    "en": "Buffet order"
  },
  "take_your_minute_review": {
    "ru": "Пожалуйста уделите минуту Вашего внимания и оставьте отзыв. Нам очень важно любое Ваше мнение!",
    "kz": "Бір минут назарыңызды бөліп, пікір қалдырыңыз. Сіздің пікіріңіз біз үшін өте маңызды!",
    "en": "Please take a minute of your attention and leave a review. Your opinion is very important to us!"
  },
  "leave_review": {
    "ru": "Оставьте отзыв",
    "kz": "Пікірді қалдыру",
    "en": "Leave review"
  },
  "enter_your_review": {
    "ru": "Введите свой отзыв",
    "kz": "Пікіріңізді жазыңыз",
    "en": "Enter your review"
  },
  "to_leave_review": {
    "ru": "Оставить отзыв",
    "kz": "Пікірді жіберу",
    "en": "Send review"
  },
  "enter_name": {
    "ru": "Введите имя",
    "kz": "Атыңызды жазыңыз",
    "en": "Enter your name"
  },
  "repeat_order": {
    "ru": "Повторить заказ",
    "kz": "Тапсырысты қайталау",
    "en": "Repeat order"
  },
  "cashback": {
    "ru": "Кэшбек",
    "kz": "Кэшбек",
    "en": "Cashback"
  },
  "download_app": {
    "ru": "Скачайте мобильное приложение и закажите любимое блюдо на дом!",
    "kz": "Мобильді қосымшаны жүктеп алыңыз және сүйікті тағамыңызға үйде тапсырыс беріңіз!",
    "en": "Download the mobile app and order your favorite dish at home!"
  },
  "available_in_app_store": {
    "ru": "<span class=\"small__text\">Загрузите в</span><span class=\"big__text\">App Store</span>",
    "kz": "<span class=\"big__text\">App Store-дан</span><span class=\"small__text\">қол жетімді</span>",
    "en": "<span class=\"small__text\">Download on</span><span class=\"big__text\">App Store</span>"
  },
  "available_in_play_market": {
    "ru": "<span class=\"small__text\">Доступно в</span><span class=\"big__text\">Play Market</span>",
    "kz": "<span class=\"big__text\">Play Market-те</span><span class=\"small__text\">қол жетімді</span>",
    "en": "<span class=\"small__text\">Available in the</span><span class=\"big__text\">Play Market</span>"
  },
  "available_in_app_store_h_tags": {
    "ru": "<h6>Загрузите в</h6><h4>App Store</h4>",
    "kz": "<h4>App Store-дан</h4><h6>қол жетімді</h6>",
    "en": "<h6>Download on</h6><h4>App Store</h4>"
  },
  "available_in_play_market_h_tags": {
    "ru": "<h6>Доступно в</h6><h4>Play Market</h4>",
    "kz": "<h4>Play Market-те</h4><h6>қол жетімді</h6>",
    "en": "<h6>Available in the</h6><h4>Play Market</h4>"
  },
  "about_us": {
    "ru": "О нас",
    "kz": "Біз туралы",
    "en": "About us"
  },
  'deliv_price': {
    "ru": "Зоны",
    "kz": "Аймак",
    "en": "Zones"
  },
  "reservate_any_restaurant": {
    "ru": "Бронирование столиков доступно в любых наших ресторанах",
    "kz": "Үстелді брондау біздің мейрамханаларымыздың кез келгенінде қол жетімді",
    "en": "Table reservations are available at any of our restaurants"
  },
  "г.Алматы": {
    "ru": "г. Алматы",
    "kz": "Алматы қ.",
    "en": "Almaty"
  },
  "г. Шымкент": {
    "ru": "г. Шымкент",
    "kz": "Шымкент қ.",
    "en": "Shymkent"
  },
  "г. Караганда": {
    "ru": "г. Караганда",
    "kz": "Қарағынды қ.",
    "en": "Qaragandy"
  },
  "г. Нур-Султан": {
    "ru": "г. Нур-Султан",
    "kz": "Нұр-Сұлтан қ.",
    "en": "Nur-Sultan"
  },
  "г. Астана": {
    "ru": "г. Астана",
    "kz": "Астана қ.",
    "en": "Astana"
  },
  "Алматы": {
    "ru": "г. Алматы",
    "kz": "Алматы қ.",
    "en": "Almaty"
  },
  "Шымкент": {
    "ru": "г. Шымкент",
    "kz": "Шымкент қ.",
    "en": "Shymkent"
  },
  "Караганда": {
    "ru": "г. Караганда",
    "kz": "Қарағынды қ.",
    "en": "Qaragandy"
  },
  "Нур-Султан": {
    "ru": "г. Нур-Султан",
    "kz": "Нұр-Сұлтан қ.",
    "en": "Nur-Sultan"
  },
  "Астана": {
    "ru": "г. Астана",
    "kz": "Астана қ.",
    "en": "Astana"
  },
  "Данные профиля успешно обновлены!": {
    "ru": "Данные профиля успешно обновлены!",
    "kz": "Профиль деректері сәтті жаңартылды!",
    "en": "Profile data updated successfully!"
  },
  "Логин или пароль неверный!": {
    "ru": "Логин или пароль неверный!",
    "kz": "Аты немесе құпия сөз дұрыс емес!",
    "en": "Wrong login or password!"
  },
  "Указан неверный код из СМС!": {
    "ru": "Указан неверный код из СМС!",
    "kz": "Жарамсыз SMS коды енгізілді!",
    "en": "Wrong SMS code!"
  },
  "Не указан телефон!": {
    "ru": "Не указан телефон!",
    "kz": "Телефон енгізілмеген!",
    "en": "Phone number field is empty!"
  },
  "Пользователь с указанным номером не зарегистрирован!": {
    "ru": "Пользователь с указанным номером не зарегистрирован!",
    "kz": "Көрсетілген нөмірі бар пайдаланушы тіркелмеген!",
    "en": "There is no registeredd user with this phone number!"
  },
  "OK": {
    "ru": "OK",
    "kz": "OK",
    "en": "OK"
  },
  "Пожалуйста, заполните все поля!": {
    "ru": "Пожалуйста, заполните все поля!",
    "kz": "Барлық өрістерді толтырыңыз!",
    "en": "Please fill all fields!"
  },
  "Пожалуйста, введите корректный код!": {
    "ru": "Пожалуйста, введите корректный код!",
    "kz": "Жарамды кодты енгізіңіз!",
    "en": "Please enter correct code!"
  },
  "check_inserted_date": {
    "ru": "Проверьте введенную дату.",
    "kz": "Енгізген күнді тексеріңіз.",
    "en": "Check inserted date."
  },
  "Пароль успешно изменен!": {
    "ru": "Пароль успешно изменен!",
    "kz": "Құпия сөз сәтті өзгертілді!",
    "en": "Password changed successfully!"
  },
  "Доставка в данное время не работает!": {
    "ru": "Доставка в данное время не работает!",
    "kz": "Қазіргі уақытта жеткізу мүмкін емес!",
    "en": "Delivery is not available at this time!"
  },
  "okay": {
    "ru": "Хорошо",
    "kz": "Жақсы",
    "en": "Okay"
  },
  "yourOrderAccept": {
    "en": "Your order has been accepted!",
    "kz": "Тапсырыңыз қабылданды!",
    "ru": "Ваш заказ принят!"
  },
  "ERROR": {
    "en": "Something went wrong!",
    "kz": "Бірдеңе дұрыс болмады!",
    "ru": "Что то пошло не так!"
  },
  "error": {
    "en": "Error",
    "kz": "Қате",
    "ru": "Ошибка"
  },
  "REFUNDED": {
    "en": "The product has been returned!",
    "kz": "Тауарды қайтару жүзеге асырылды!",
    "ru": "Осуществлен возврат товара!"
  },
  "DECLINED": {
    "en": "Payment refused!",
    "kz": "Төлеуден бас тартылды!",
    "ru": "Отказано в оплате!"
  },
  "CANCELED": {
    "en": "The operation was aborted!",
    "kz": "Операция ортасынан үзілді!",
    "ru": "Операция была прервана!"
  },
  "EXPIRED": {
    "en": "The order has expired!",
    "kz": "Тапсырыстың төлеу мерзімі аяқталды!",
    "ru": "Истек срок действия заказа!"
  },
  "Close": {
    "en": "Close",
    "kz": "Жабу",
    "ru": "Закрыть"
  },

  "": {
    "ru": "Неизвестно",
    "kz": "Белгісіз",
    "en": "Unknown"
  },
  "": {
    "ru": "Неизвестно",
    "kz": "Белгісіз",
    "en": "Unknown"
  }
}


export default translates